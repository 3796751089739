import * as React from "react"
import { useEffect } from "react";
import { graphql, Link } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import Layout from "../components/layout"
import {
    Container,
    Flex,
    Box,
    Space,
    Heading,
    Text,
    Avatar,
    HomepageImage,
    FlexList,
} from "../components/ui"

export interface BlogAuthor {
    id: string
    name: string
    avatar: HomepageImage
}

interface Anchor {
    text: string
    href: string
}

export interface ArtworkProps {
    data: {
        datoCmsArtwork: {
            id: string
            title: string
            image: {
                gatsbyImageData: IGatsbyImageData
                url: string
                width: number
                height: number
            }
            extraImages: {
                gatsbyImageData: IGatsbyImageData
                url: string
                width: number
                height: number
            }[]
            year: number
            size: {
                height: number
                width: number
            }[]
            metadata: {
                title: string
                description: string
                twitterCard: string
            }
            descriptionNode: {
                childMarkdownRemark: {
                    html: string
                }
            }
            medium: string
            externalProductLink: Anchor[]
            sold: boolean
            forSale: boolean
        }
    }
}

export default function BlogPost(props: ArtworkProps) {
    const artwork = props.data.datoCmsArtwork;
    const size = artwork.size[0];

    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: "#gallery",
            children: "a",
            thumbSelector: ".lb",
            pswpModule: () => import("photoswipe"),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);

    let sellerElement = <div />
    const productLink: Anchor | undefined = artwork.externalProductLink[0]

    if (productLink) {
        sellerElement = (
            <div>
                {artwork.sold ? "Sold" : "For sale"} on <a href={productLink.href}>{productLink.text}</a>
            </div>
        )
    }
    else if (artwork.sold) {
        sellerElement = <div>Sold</div>
    }
    else if (artwork.forSale) {
        sellerElement = (
            <div>
                For sale - please get in <Link to="/contact">contact</Link> to purchase
            </div>
        )
    }

    return (
        <Layout title={artwork.title}>
            <Container>
                <Flex responsive>
                    <div id="gallery">
                        <Box width="fitContent">
                            <a
                                href={artwork.image.url}
                                target="_blank"
                                data-pswp-width={artwork.image.width}
                                data-pswp-height={artwork.image.height}
                            >
                                <GatsbyImage imgClassName="lb" image={artwork.image.gatsbyImageData} alt="" />
                            </a>
                            <FlexList marginY={2} variant="center">
                                {artwork.extraImages.map((image) =>
                                    <a href={image.url} target="_blank" data-pswp-width={image.width} data-pswp-height={image.height}>
                                        <GatsbyImage imgClassName="lb" image={image.gatsbyImageData} alt="" />
                                    </a>
                                )}
                            </FlexList>
                        </Box>
                    </div>
                    <Box style={{ alignSelf: "baseline" }}>
                        <Space size={4} />
                        <Heading as="h1">
                            {artwork.title}
                        </Heading>

                        <Box>
                            Created in {artwork.year}
                        </Box>
                        <Box>
                            {artwork.medium}
                        </Box>
                        <Box>
                            {size.width} x {size.height} cm ({(size.width / 2.54).toFixed(1)}" x {(size.height / 2.54).toFixed(1)}")
                        </Box>

                        <Space size={4} />

                        <div
                            dangerouslySetInnerHTML={{
                                __html: artwork.descriptionNode.childMarkdownRemark.html,
                            }}
                        />
                        <Space size={5} />

                        <hr style={{ width: "20%", marginLeft: 0 }} />

                        <Space size={4} />

                        {sellerElement}
                    </Box>
                </Flex>
            </Container>
        </Layout >
    )
}

export const query = graphql`
  query ArtworkContent($id: String!) {
    datoCmsArtwork(id: {eq: $id}) {
      id
      title
      image {
          gatsbyImageData(width: 600)
          url
          width
          height
      }
      extraImages {
          gatsbyImageData(width: 50, height: 50)
          url
          width
          height
      }
      year
      size {
        height
        width
      }
      metadata {
        title
        description
        twitterCard
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      medium
      externalProductLink {
          text
          href
      }
      sold
      forSale
    }
  }
`
